import React from 'react';
import { navigate } from "gatsby"


import styles from '../css/components/ContactForm.module.css';
import buttonStyles from '../css/components/Button.module.css';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()
    this.state = {}
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.ContactForm.current

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(response => {
        console.log("====================================")
        console.log(`${JSON.stringify(response, null, 2)}`)
        console.log("====================================")
        navigate(form.getAttribute("action"))
      })
      .catch(error => {
        console.log("====================================")
        console.log(`error in submiting the form data:${error}`)
        console.log("====================================")
      })
  }
  render() {
    const name = 'Jméno';
    const email = 'Email';
    const message = 'Zpráva';
    const send = 'Poslat zprávu';
    const subject = 'Předmět zprávy';

    return (
      <form
        className={styles.form}
        method="POST"
        name="contact"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success/"
        onSubmit={this.handleSubmit}
        ref={this.ContactForm}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>
        <div className={styles.field}>
          <label htmlFor="name">{name}</label>
          <input type="text" name="name" id="name" onChange={this.handleChange} required />
        </div>
        <div className={styles.field}>
          <label htmlFor="email">{email}</label>
          <input type="email" name="email" id="email" onChange={this.handleChange} required />
        </div>
        <div className={`${styles.field} ${styles.span2}`}>
          <label htmlFor="subject">{subject}</label>
          <input type="text" name="subject" id="subject" onChange={this.handleChange} />
        </div>
        <div className={`${styles.field} ${styles.span2}`}>
          <label htmlFor="message">{message}</label>
          <textarea name="message" id="message" rows="6" onChange={this.handleChange} required />
        </div>
        <div className={`${styles.field} ${styles.span2} ta-center`}>
          <button className={buttonStyles.btn} type="submit">
            {send}
          </button>
        </div>
      </form>
    );
  }
}
