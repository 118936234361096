import React from 'react';
import { graphql } from 'gatsby';

// components
import Container from '../components/Container';
import ContactForm from '../components/ContactForm';

// css
import css from '../css/pages/contact.module.css';

// contact item
class ContactItem extends React.Component {
  render() {
    const link = this.props.link;

    if (link) {
      return (
        <div className="contact-item">
          <span className="contact-item__label">{this.props.label}</span>:{' '}
          <span className="contact-item__content">
            <a href={this.props.link} target="_blank" rel="noopener noreferrer">
              {this.props.content}
            </a>
          </span>
        </div>
      );
    } else {
      return (
        <div className="contact-item">
          <span className="contact-item__label">{this.props.label}</span>:{' '}
          <span className="contact-item__content">{this.props.content}</span>
        </div>
      );
    }
  }
}

export default ({ data, location }) => {
  // const title = data.datoCmsContact.title;
  const contactText = data.datoCmsContact.contactTextNode.childMarkdownRemark.html;

  const contactTitle = data.datoCmsContact.contactTitle;
  const contactDetails = data.datoCmsContact.contactDetails;
  const contact = contactDetails.map(edge => (
    <ContactItem key={edge.id} label={edge.label} content={edge.content} link={edge.link} />
  ));

  const bankTitle = data.datoCmsContact.bankTitle;
  const bankDetails = data.datoCmsContact.bankDetails;
  const bank = bankDetails.map(edge => (
    <ContactItem key={edge.id} label={edge.label} content={edge.content} link={edge.link} />
  ));

  return (
    <Container title="Kontakt" permalink={location.pathname}>
      <section>
        <div className="wrap-s">
          <div className="ta-center" dangerouslySetInnerHTML={{ __html: contactText }} />
          <div className="m-2">
            <ContactForm />
          </div>
        </div>

        <div className="wrap m-3">
          <div className={css.footer}>
            <div className={css.footerBlock}>
              <h2 className="h3">{contactTitle}</h2>
              <div className={css.contact}>{contact}</div>
            </div>
            <div className={css.footerBlock}>
              <h2 className="h3">{bankTitle}</h2>
              <div className={css.contact}>{bank}</div>
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
};

export const query = graphql`
  query {
    datoCmsContact {
      title
      contactTextNode {
        childMarkdownRemark {
          html
        }
      }
      contactTitle
      contactDetails {
        id
        label
        content
        link
      }
      bankTitle
      bankDetails {
        id
        label
        content
        link
      }
    }
  }
`;
